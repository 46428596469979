import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer, type Params } from '@angular/router';
import { UserService } from '@clover/core/services/user.service';
import {
  FilterableTaskStatus,
  Task,
  TaskCommunicationRole,
  TaskPerformer,
  TaskPerformerCompany,
  TaskPreview,
  TaskProduct,
  TaskRole,
  TaskStatus,
  TaskType,
  type TaskEmailAccessLevel,
} from '@conversations/tasks/tasks.model';
import {
  getOffsetPagingOptionsParams,
  getPagingOptionsParams,
  OffsetPagingOptions,
  OffsetPagingWrapper,
  PagingOptions,
  PagingWrapper,
} from '@core/helpers/paging';
import { ccPreventDuplicateSubscriptions } from '@core/helpers/prevent-duplicate-subscriptions';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { HttpService } from '@core/services/http.service';
import { ToastType } from '@design/overlays/toast/toast';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import type { NoAccessTaskPlaceholder } from '../conversation/active-conversation/conversation/conversation-messages/message/message-task-references/message-task-references.component';

export interface TaskPerformerResponse {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  logoUrl: string;
}

export interface TaskPerformerCompanyResponse {
  id: number;
  name: string;
  logoUrl: string;
}

interface TaskProductResponse {
  id: number;
  name: string;
  itemNumber: string;
  globalTradeIdentificationNumber: string;
  logoUrl: string | undefined;
}

export interface TaskCommunicationRoleResponse {
  id: number;
  key: string;
  title: string;
  description: string | undefined;
  sortOrder: number;
}

export interface FullTaskResponse {
  id: number;
  name: string;
  description: string;
  acceptedByUser: TaskPerformerResponse | undefined;
  assignedTo: {
    communicationRoles: TaskCommunicationRoleResponse[];
    users: TaskPerformerResponse[];
  };
  assignedToCompany: TaskPerformerCompanyResponse;
  assignedByUser: TaskPerformerResponse | undefined;
  assignedByCompany: TaskPerformerCompanyResponse;
  assignedByCompanyProduct: TaskProductResponse | undefined;
  status: TaskStatus;
  taskRole: TaskRole;
  workflowId: number;
  workflowSnapshotId: number;
  isAccepted: boolean;
  completion: number;
  assignedAt: string;
}

export type TaskPreviewResponse = Pick<
  FullTaskResponse,
  | 'id'
  | 'name'
  | 'acceptedByUser'
  | 'assignedTo'
  | 'assignedToCompany'
  | 'assignedByUser'
  | 'assignedByCompany'
  | 'assignedByCompanyProduct'
  | 'status'
  | 'taskRole'
  | 'assignedAt'
  | 'completion'
> & {
  emailsAccess: {
    [email: string]: TaskEmailAccessLevel;
  };
};

export type TasksSortingProperty = 'id' | 'createdAt' | 'score' | 'name' | 'completionRate';

export function mapTask(t: FullTaskResponse): Task {
  return {
    id: t.id,
    name: t.name,
    description: t.description,
    acceptedBy: t.acceptedByUser ? mapTaskPerformer(t.acceptedByUser) : undefined,
    assigneeRoles: t.assignedTo.communicationRoles.map(mapTaskRole),
    assigneeUsers: t.assignedTo.users ? t.assignedTo.users.map(mapTaskPerformer) : [],
    assigneeCompany: mapTaskPerformerCompany(t.assignedToCompany),
    assigner: t.assignedByUser ? mapTaskPerformer(t.assignedByUser) : undefined,
    assignerCompany: mapTaskPerformerCompany(t.assignedByCompany),
    product: t.assignedByCompanyProduct ? mapTaskProduct(t.assignedByCompanyProduct) : undefined,
    status: t.status,
    taskRole: t.taskRole,
    workflowId: t.workflowId,
    workflowSnapshotId: t.workflowSnapshotId,
    accepted: t.isAccepted,
    completion: t.completion,
    assignedAt: t.assignedAt,
    emailAccess: [],
  };
}

export function mapTaskPreview(t: TaskPreviewResponse): TaskPreview | undefined {
  try {
    return {
      id: t.id,
      name: t.name,
      acceptedBy: t.acceptedByUser ? mapTaskPerformer(t.acceptedByUser) : undefined,
      assigneeRoles: t.assignedTo.communicationRoles.map(mapTaskRole),
      assigneeUsers: t.assignedTo.users ? t.assignedTo.users.map(mapTaskPerformer) : [],
      assigneeCompany: mapTaskPerformerCompany(t.assignedToCompany),
      assigner: t.assignedByUser ? mapTaskPerformer(t.assignedByUser) : undefined,
      assignerCompany: mapTaskPerformerCompany(t.assignedByCompany),
      product: t.assignedByCompanyProduct ? mapTaskProduct(t.assignedByCompanyProduct) : undefined,
      status: t.status,
      taskRole: t.taskRole,
      completion: t.completion,
      assignedAt: t.assignedAt,
      emailAccess: Object.entries(t.emailsAccess || {}).map(([email, access]) => ({ email, access })),
    };
  } catch {
    return undefined;
  }
}

export function mapTaskRole(r: TaskCommunicationRoleResponse): TaskCommunicationRole {
  return {
    id: r.id,
    key: r.key,
    title: r.title,
    description: r.description || undefined,
    sortOrder: r.sortOrder,
  };
}

export function mapTaskPerformer(p: TaskPerformerResponse): TaskPerformer {
  return {
    id: p.id,
    firstName: p.firstName,
    lastName: p.lastName,
    title: p.title,
    avatarUrl: p.logoUrl,
  };
}

export function mapTaskPerformerCompany(c: TaskPerformerCompanyResponse): TaskPerformerCompany {
  return {
    id: c.id,
    name: c.name,
    logoUrl: c.logoUrl,
  };
}

function mapTaskProduct(p: TaskProductResponse): TaskProduct {
  return {
    id: p.id,
    name: p.name,
    itemNumber: p.itemNumber,
    gtin: p.globalTradeIdentificationNumber,
    imageUrl: p.logoUrl,
  };
}

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);
  private readonly portalService = inject(CdkPortalService);
  private readonly userService = inject(UserService);
  private readonly translate = inject(TranslateService);

  private _taskUpdated$ = new Subject<Task>();

  subscribeToTaskUpdates(id: number): Observable<Task> {
    return this._taskUpdated$.asObservable().pipe(filter((task) => task.id === id));
  }

  getTask(id: number): Observable<Task> {
    const urlTree = this.router.createUrlTree(['api', 'tasks', id, 'details']);
    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<FullTaskResponse>(path).pipe(map(mapTask));
  }

  getTasksSuggestions(
    query: string,
    pagingOptions?: PagingOptions<TasksSortingProperty>,
  ): Observable<PagingWrapper<TaskPreview>> {
    const urlTree = this.router.createUrlTree(['api', 'tasks', 'suggestions'], {
      queryParams: {
        query,
        ...getPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http
      .getV2<PagingWrapper<TaskPreviewResponse>>(path)
      .pipe(map((response) => ({ ...response, data: response.data.map(mapTaskPreview) })));
  }

  getTasksPreviews(
    taskIds: number[],
    associatedEmails: string[],
  ): Observable<(TaskPreview | NoAccessTaskPlaceholder)[]> {
    const urlTree = this.router.createUrlTree(['api', 'tasks', 'previews']);

    const path = this.serializer.serialize(urlTree);
    return this.http
      .postV2<TaskPreviewResponse[]>(path, {
        ids: taskIds,
        emails: associatedEmails,
      })
      .pipe(
        map((tasks) => {
          return tasks.map((task, index) => {
            const mappedTask = mapTaskPreview(task);
            if (mappedTask) return mappedTask;

            const unavailableTask = tasks[index];
            return {
              id: unavailableTask.id,
              unavailable: true,
              emailAccess: Object.entries(unavailableTask.emailsAccess || {}).map(([email, access]) => ({
                email,
                access,
              })),
            } as NoAccessTaskPlaceholder;
          });
        }),
      );
  }

  searchTasks(
    query: string,
    type: TaskType,
    status: FilterableTaskStatus,
    onlyAssignedToCurrentUser: boolean, // Only applicable to Received tasks
    pagingOptions?: OffsetPagingOptions<TasksSortingProperty>,
    extraQueryParams: Params = {},
  ): Observable<OffsetPagingWrapper<TaskPreview>> {
    const urlTree = this.router.createUrlTree(
      ['api', type === TaskType.Received ? 'receivedCompanyTasks' : 'sentCompanyTasks', 'search'],
      {
        queryParams: {
          query,
          taskType: status,
          assignedToUserId:
            type === TaskType.Received && onlyAssignedToCurrentUser ? this.userService.userProfile.id : undefined,
          ...extraQueryParams,
          ...getOffsetPagingOptionsParams(pagingOptions),
        },
      },
    );

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<OffsetPagingWrapper<TaskPreviewResponse>>(path).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(mapTaskPreview),
      })),
    );
  }

  getUsersIncompleteTasksCount(): Observable<number> {
    return this.searchTasks('', TaskType.Received, FilterableTaskStatus.Incomplete, true, { limit: 1, offset: 0 }).pipe(
      map((response) => response.total),
    );
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  assignToCurrentUser(taskId: number): Subscription {
    return this.http
      .postV2<void>(`api/receivedCompanyTasks/${taskId}/accept`, {})
      .pipe(
        tap(() => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.tasks.toasts.accepted'),
            ToastType.Success,
          );
          this.requestTaskUpdate(taskId);
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.tasks.toasts.failedToAccept'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  sendReminder(taskId: number): Subscription {
    return this.http
      .postV2<void>(`api/sentCompanyTasks/${taskId}/sendReminder`, {})
      .pipe(
        tap(() => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.tasks.toasts.reminderSent'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.tasks.toasts.failedToSendReminder'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  closeTask(taskId: number): Subscription {
    return this.http
      .postV2<void>(`api/sentCompanyTasks/${taskId}/close`, {})
      .pipe(
        tap(() => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.tasks.toasts.closed'),
            ToastType.Success,
          );
          this.requestTaskUpdate(taskId);
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.tasks.toasts.failedToClose'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  reroute(taskId: number): void {
    // TODO (Oleksandr D.): This should be rewritten when we implement new task re-route modal.
    this.requestTaskUpdate(taskId);
  }

  @ccPreventDuplicateSubscriptions('last-wins')
  private requestTaskUpdate(taskId: number): Subscription {
    return this.getTask(taskId).subscribe((task) => this._taskUpdated$.next(task));
  }
}
