import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TasksService } from '../../tasks.service';
import { ModalService } from '@core/services/modal.service';
import { ContactSelectorModalComponent } from '@clover/network/modals/contact-selector-modal/contact-selector-modal.component';
import { Router } from '@angular/router';
import { ToastrService } from '@core/services/toastr.service';
import { TaskReassignedModalComponent } from '../task-reassigned-modal/task-reassigned-modal.component';
import { DepartmentSelectorModalComponent } from '@clover/network/modals/department-selector-modal/department-selector-modal.component';
import { HistoryService } from '@core/services/history-service.service';
import { UserService } from '@core/services/user.service';
import { SignalrService } from '@core/services/signalr.service';
import { TranslateModule } from '@ngx-translate/core';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { TasksService as TasksServiceV4 } from '@conversations/tasks/tasks.service';

@Component({
  selector: 'reassign-task-modal',
  templateUrl: './reassign-task-modal.component.html',
  styleUrls: ['./reassign-task-modal.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class ReassignTaskModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly modalService = inject(ModalService);
  private readonly taskService = inject(TasksService);
  private readonly router = inject(Router);
  private readonly toastr = inject(ToastrService);
  private readonly historyService = inject(HistoryService);
  private readonly userService = inject(UserService);
  private readonly signalR = inject(SignalrService);
  @Input() taskId: number;
  @Input() fromConversation = false;

  private readonly tasksServiceV4 = inject(TasksServiceV4);

  public selectDepartments(): void {
    this.activeModal.close();
    this.modalService
      .open({
        content: DepartmentSelectorModalComponent,
        inputs: {
          isAssigningTask: true,
        },
        options: {
          size: 'sm',
          windowClass: 'modal-background-transparent',
        },
      })
      .result.then((res) => {
        this.taskService
          .reassignTaskToDepartments(
            this.taskId,
            res.map((department) => department.key),
          )
          .then(() => {
            this.navigateAfterSuccess();

            if (this.fromConversation) this.tasksServiceV4.reroute(this.taskId);

            this.modalService.open({
              content: TaskReassignedModalComponent,
              inputs: {
                assignedToContact: false,
              },
              options: {
                size: 'sm',
              },
            });
          })
          .catch((err) => {
            this.toastr.displayServerErrors(err);
          });
      })
      .catch(() => {});
  }

  public selectContact(): void {
    this.activeModal.close();
    this.modalService
      .open({
        content: ContactSelectorModalComponent,
        inputs: {
          isAssigningTask: true,
          hideNewUserBtn: this.userService.userCompany.licenseTier !== 'Individual',
        },
        options: {
          size: 'xl',
          windowClass: 'modal-background-transparent',
        },
      })
      .result.then((res) => {
        const id = res?.contacts[0]?.id || res?.contacts[0]?.contact.id;
        this.taskService
          .reassignTaskToContact(this.taskId, id)
          .then(() => {
            this.navigateAfterSuccess();

            if (this.fromConversation) this.tasksServiceV4.reroute(this.taskId);

            this.modalService.open({
              content: TaskReassignedModalComponent,
              inputs: {
                assignedToContact: true,
                isNewContact: res.isNewContact,
              },
              options: {
                size: 'sm',
              },
            });
          })
          .catch((err) => {
            this.toastr.displayServerErrors(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private navigateAfterSuccess(): void {
    if (this.fromConversation) return;
    if (this.historyService.getPreviousUrl() === '/dashboard') {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/tasks']);
    }
  }
}
