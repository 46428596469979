import { Component, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilesPageComponent } from '../../files-page/files-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { FilesModule } from '@clover/files/files.module';

@Component({
  selector: 'files-selector-modal',
  templateUrl: './files-selector-modal.component.html',
  styleUrls: ['./files-selector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective, TranslateModule, FilesModule],
})
export class FilesSelectorModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  @ViewChild('filesPage') filesPage: FilesPageComponent;

  public get areFilesSelected(): boolean {
    return this.filesPage?.selectedFiles?.length > 0;
  }

  public selectFiles(): void {
    this.activeModal.close(this.filesPage.selectedFiles);
  }
}
