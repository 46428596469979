import { Injectable, inject } from '@angular/core';
import { HttpService } from '../core/services/http.service';
import { ModalService } from '../core/services/modal.service';
import { OkModalComponent } from '../core/modals/ok-modal/ok-modal.component';
import { type Task } from './models/task';
import { TaskAssigmentType } from './models/task-assigment-type';
import { type Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private readonly http = inject(HttpService);
  private readonly modalService = inject(ModalService);

  public async getIncompleteTasks(count: number, userId: number): Promise<any> {
    return await this.http.get(
      `api/receivedCompanyTasks/search?taskType=Incomplete&Limit=${count}&AssignedToUserId=${userId}`,
    );
  }

  public async getTask(id: number): Promise<Task> {
    return await this.http.get(`api/receivedCompanyTasks/${id}`);
  }

  public async acceptTask(id: number): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${id}/accept`, {}).catch((res) => {
      const err = res.error.errors[0];
      if (!err) {
        throw err;
      }

      if (err.errorCode === 'task_already_accepted') {
        this.modalService.open({
          content: OkModalComponent,
          inputs: {
            title: 'tasksPage.taskAlreadyAccepted',
            text: 'tasksPage.taskAlreadyAcceptedMsg',
          },
        });
        throw err;
      }

      if (err.errorCode === 'campaign_not_in_progress') {
        this.modalService.open({
          content: OkModalComponent,
          inputs: {
            title: 'tasksPage.campaignClosed',
            text: 'tasksPage.campaignClosedMsg',
          },
        });
        throw err;
      }
    });
  }

  public async startTask(taskId: number): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${taskId}/start`, {});
  }

  public async submitStep(taskId: number, stepId: number, data: any): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${taskId}/steps/${stepId}/submit`, { data });
  }

  public async previousStep(taskId: number, stepId: number, data: any): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${taskId}/steps/${stepId}/back`, { data });
  }

  public async reassignTaskToContact(taskId: number, contactId: number): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${taskId}/reassign`, {
      type: TaskAssigmentType.ToUser,
      userContactId: contactId,
    });
  }

  public async reassignTaskToDepartments(taskId: number, departmentIds: string[]): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${taskId}/reassign`, {
      type: TaskAssigmentType.ToDepartment,
      communicationRoles: departmentIds,
    });
  }

  public async saveTaskForLater(taskId: number, stepId: number, data: any): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${taskId}/steps/${stepId}/save`, { data });
  }

  public closeTask(taskId: number): Observable<any> {
    return this.http.postV2(`api/sentCompanyTasks/${taskId}/close`, {});
  }

  public async sendReminders(taskId: number): Promise<any> {
    return await this.http.post(`api/sentCompanyTasks/${taskId}/sendReminder`, {});
  }

  public async requestEsign(taskId: number, stepId: number): Promise<any> {
    return await this.http.post(`api/tasks/${taskId}/steps/${stepId}/esign/request`, {});
  }

  public async getEsignUrl(taskId: number, stepId: number, signatureId: string): Promise<any> {
    return await this.http.post(`api/tasks/${taskId}/steps/${stepId}/esign/request/${signatureId}/sign_url`, {});
  }

  public async copyResults(taskId: number, forProductIds: number[]): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${taskId}/copy`, {
      forProductIds,
    });
  }

  public async applyResults(taskId: number, taskInstanceIds: number[]): Promise<any> {
    return await this.http.post(`api/receivedCompanyTasks/${taskId}/applyResults`, {
      taskInstanceIds,
    });
  }

  public async countVendorProducts(companyId: number, vendorId: number, excludedProductIds: number[]): Promise<number> {
    const url = `/api/companies/${companyId}/products/search?vendorId=${vendorId}`;
    const data = {
      ExcludedProductIds: excludedProductIds,
    };
    return await this.http.post(url, data).then((res) => res?.total || 0);
  }

  public async getNotStartedTasksWithSameWorkflow(task: Task, idsToExclude: number[]): Promise<any> {
    let url = `api/receivedCompanyTasks/search?WorkflowId=${task.workflow.workflowId}&WorkflowSnapshotId=${task.workflow.workflowSnapshotId}&taskType=NotStarted`;

    idsToExclude.forEach((id) => (url += `&ListIdsToExclude=${id}`));
    return await this.http.get(url);
  }
}
